const getLocationIcon = (locations = []) => {
  const DEFAULT_ICON = `${'location_on'}`;
  const isLocationsEmpty = locations?.length === 0;

  if (isLocationsEmpty) return DEFAULT_ICON;

  const locationIcons = locations?.map?.((location) => {
    switch (location?.type) {
      case 'online':
        return 'videocam';
      case 'onsite':
        return 'location_on';
      case 'distance':
        return 'videocam';
      default:
        return null;
    }
  });

  const isIconValid = !Array.isArray(locationIcons);
  if (isIconValid) return DEFAULT_ICON;

  return `${locationIcons[0]}`;
};

export default getLocationIcon;
