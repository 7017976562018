import React from 'react';
import { Link } from 'gatsby';
import CardCategory from '@components/Cards/CardCategory';
import CardTitle from '@components/Cards/CardTitle';
import CardAuthor from '@components/Cards/CardAuthor';
import CardMiddot from '@components/Cards/CardMiddot';
import CardPayModel from '@components/Cards/CardPayModel';
import CourseInfo from '@components/CourseInfo';

const CourseCard = ({
  className = '',
  category = 'Skilled Trades',
  costType = 'Paid',
  title = 'First Response to Fire Incidents',
  author = 'Kumul Petroleum Academy',
  middotContent = ['Register until Aug 25', 'Starts on Nov 28, 2020'],
  location = { name: 'No Location', icon: 'No Icon' },
  duration = '12 months',
  level = 'Beginner',
  link = '',
  logoUrl = '',
  alt = 'sponsor-logo',
}) => {
  return (
    <Link className={`course-card-container ${className}`} to={link}>
      <section className={'course-card-top-group'}>
        <div className="category-title-group">
          <div className={'first-column-group'}>
            <CardCategory label={category} />
            <CardPayModel label={costType} />
          </div>
          <CardTitle label={title} />
        </div>
        <div className="author-middot-flex-container">
          <CardAuthor label={author} />
          <CardMiddot labels={middotContent} />
        </div>
      </section>
      <CourseInfo
        locationIcon={location.icon}
        locationName={location?.name}
        duration={duration?.label}
        level={level}
        logoUrl={logoUrl}
        alt={alt}
      />
    </Link>
  );
};

export default CourseCard;
