import React from 'react';
import Carousel from 'antd/lib/carousel';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ArticleCard from '@components/Cards/ArticleCard';
import EventCard from '@components/Cards/EventCard';
import CourseCard from '@components/Cards/CourseCard';
import classNames from 'classnames';

const CardCarousel = ({
  title = 'Untitled',
  items = [],
  className = '',
  linkRoot = '',
  type,
}) => {
  const settings = {
    dots: true,
    adaptiveHeight: false,
    infinite: false,
    speed: 600,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
    touchThreshold: 100,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          centerMode: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const generateCards = () => {
    if (type === '') return;

    if (type === 'eventCard') {
      return items.map((cardItem, idx) => {
        return <EventCard {...cardItem} key={idx} />;
      });
    }

    if (type === 'courseCard') {
      return items.map((cardItem, idx) => {
        const courseLink = `${linkRoot}${cardItem?.link}`;
        return <CourseCard {...cardItem} link={courseLink} key={idx} />;
      });
    }

    const articleCards = items?.map((cardItem) => {
      const slug = cardItem?.slug;
      const link = `${linkRoot}${slug}`;
      return (
        <ArticleCard
          data={cardItem}
          key={`cards__${cardItem?.id}`}
          link={link}
        />
      );
    });

    return articleCards;
  };

  const generatedCards = generateCards();
  const isGeneratedCardsSingle = generatedCards?.length === 1;

  return (
    <section className="card-carousel-container">
      <section className="card-carousel-title">
        <span className="_section-label">{title}</span>
      </section>

      <section
        className={classNames('card-carousel', {
          'single-card-carousel': isGeneratedCardsSingle,
        })}
      >
        <Carousel {...settings} className={className}>
          {generatedCards}
        </Carousel>
      </section>
    </section>
  );
};

export default CardCarousel;
