import { isEmpty } from 'lodash';
import React from 'react';

const CardIconInfo = ({ icon = null, info = '' }) => {
  const hasNoInfo = isEmpty(info);
  if (hasNoInfo) return null;
  return (
    <div className={'card-icon-info-container'}>
      <div className={`card-icon`}>{icon}</div>
      <div className={`card-info`}>{info}</div>
    </div>
  );
};

export default CardIconInfo;
