import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
const getEventProgressStatus = (startDate, endDate, startTime, endTime) => {
  const startDateMoment = moment(startDate, 'YYYY-MM-DD');
  const endDateMoment = moment(endDate, 'YYYY-MM-DD');
  const isStartDateInvalid = !startDateMoment.isValid();
  const isEndDateInvalid = !endDateMoment.isValid();

  const isDateTimeInvalid = isStartDateInvalid && isEndDateInvalid;
  if (isDateTimeInvalid) return '';

  const todayMoment = moment();
  const thirtyDaysAfterEndDate = endDateMoment.clone().add(30, 'days');

  const isUpcoming = todayMoment.isBefore(startDateMoment);
  if (isUpcoming === true) return 'upcoming';

  const isOngoing = moment().isBetween(
    moment(`${startDate} ${startTime}`, DATE_FORMAT),
    moment(`${endDate} ${endTime}`, DATE_FORMAT)
  );

  if (isOngoing === true) return 'ongoing';

  const shouleBeDeleted = todayMoment.isAfter(thirtyDaysAfterEndDate);
  if (shouleBeDeleted === true) return 'deleted';

  const isCompleted = todayMoment.isAfter(endDateMoment);
  if (isCompleted === true) return 'completed';
};

export default getEventProgressStatus;
