import formatDateTimeString from '@helpers/methods/formatEventsDateTimeString';
import getEventProgressStatus from '@helpers/methods/getEventProgressStatus';
import checkIsInvalidContents from '../utils/checkIsInvalidContents';
import removeNullFromContents from '../utils/removeNullFromContents';

const nullifyExpiredContentFromEvent = (event) => {
  const eventProgressStatus = getEventProgressStatus(
    event?.start_date,
    event?.end_date
  );

  if (eventProgressStatus === 'deleted') {
    return null;
  }

  return event;
};

const addDateTimeToEvent = (event) => {
  const isInputFalsy = !event;
  const isNotAnObject = typeof event !== 'object';
  const isInvalidInput = isInputFalsy || isNotAnObject;
  if (isInvalidInput) return event;

  const dateTimeString = formatDateTimeString(
    event?.start_date,
    event?.end_date,
    event?.start_time,
    event?.end_time
  );

  return { ...event, datetime: dateTimeString };
};

const addTagToEvent = (event) => {
  const isInputFalsy = !event;
  const isNotAnObject = typeof event !== 'object';
  const isInvalidInput = isInputFalsy || isNotAnObject;
  if (isInvalidInput) return event;

  const isVCF = !!event?.is_virtual_career_fair;
  const eventProgressStatus = getEventProgressStatus(
    event?.start_date,
    event?.end_date,
    event?.start_time,
    event?.end_time
  );

  const isEventOngoing = eventProgressStatus === 'ongoing';
  const shouldNotAddAnOngoingTag = !(isEventOngoing && isVCF);

  if (shouldNotAddAnOngoingTag) return event;

  return { ...event, tag: 'Ongoing' };
};

const modifyEventsByDateTime = (eventsData = []) => {
  if (checkIsInvalidContents(eventsData)) {
    return [];
  }

  const updatedEventsData = eventsData.map((eventData) => {
    eventData = nullifyExpiredContentFromEvent(eventData);
    eventData = addDateTimeToEvent(eventData);
    eventData = addTagToEvent(eventData);
    return eventData;
  });

  const filteredEventsData = removeNullFromContents(updatedEventsData);

  return filteredEventsData;
};

export default modifyEventsByDateTime;
