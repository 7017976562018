import { useCookies } from 'react-cookie';

const getJobseekerProfileCookieName = () => {
  const envName = `${process.env.ENV_NAME}`;

  switch (envName) {
    case 'prod':
      return 'js-profile';
    case 'uat':
      return 'uat-js-profile';
    case 'dev':
      return 'development-js-profile';
    default:
      return 'js-profile';
  }
};

const useJobseekerProfileCookie = () => {
  const jobseekerProfileCookieName = getJobseekerProfileCookieName();
  const [cookies] = useCookies([`${jobseekerProfileCookieName}`]);

  return cookies?.[`${jobseekerProfileCookieName}`];
};

export default useJobseekerProfileCookie;
