import React from 'react';

const CardTag = ({ label = '' }) => {
  return (
    <div className={'card-tag-container'}>
      <span className={'card-tag'}>{label}</span>
    </div>
  );
};

export default CardTag;
