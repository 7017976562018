import React from 'react';
import Image from '@components/Image';
const DEFAULT_EMPLOYER_LOGO_URL =
  'https://wanpng-assets-prod.s3.ap-southeast-2.amazonaws.com/logo/default-employer-logo.png';

const Card = ({ avatarURL = '', name = '' }) => {
  return (
    <div className="vcf-employer-card">
      <div className="vcf-employer-logo">
        <Image image={avatarURL || DEFAULT_EMPLOYER_LOGO_URL} />
      </div>
      <div className="vcf-employer-name">{name}</div>
    </div>
  );
};

export default Card;
