import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Image from '@components/Image';
import MarkdownViewer from '@components/MarkdownViewer';
import isEmpty from 'lodash/isEmpty';
import Tag from 'antd/lib/tag';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import CardCarousel from '@components/CardCarousel';
import SocialSharing from '@components/SocialSharing';
import CardCategory from '@components/Cards/CardCategory';
import CardTag from '@components/Cards/CardTag';
import CardIconInfo from '@components/Cards/CardIconInfo';
import AuthorPagePreview from '@components/AuthorPagePreview';
import InquiryModal from '@components/InquiryModal';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import DesktopAds from '@components/ContentViewer/Advertisement/DesktopAds';
import MobileAds from '@components/ContentViewer/Advertisement/MobileAds';
import useEventDataQuery from './hooks/useEventArticleQuery';
import useRelatedEventsQuery from './hooks/useRelatedEventsQuery';
import moment from 'moment';
import VCFRegisterModal from '@components/VCFRegisterModal';
import VCFEmployerCard from '@components/Cards/VCFEmployerCard';

import selectAuthorProfileUrl from '@helpers/methods/modifyContents/utils/selectAuthorProfileUrl';

const EventsContentViewer = ({ data, location }) => {
  const inquiryModalProps = {
    message:
      'By inquiring, you agree to receive messages from the event provider about this event.',
    postURL: `${process.env.GATSBY_API_URL}/events-inquiries`,
    link: `${process.env.GATSBY_SITE_URL}/events/${data?.strapiEvents?.slug}`,
  };

  const [isVisibleModal, setIsVisibleModal] = useState(false);

  const previewAuthor = data?.strapiAuthorPages;
  const relatedEvents = useRelatedEventsQuery(data?.allRelatedEvents?.nodes);
  const eventData = useEventDataQuery(data?.strapiEvents);

  const eventAuthor = eventData.author == null ? '' : `by ${eventData?.author}`;
  const carouselStyle = relatedEvents?.length === 1 && 'single-card-wrapper';

  const currentDateTime = moment();

  const defaultEndTime = eventData?.endTime || '23:59:59';

  const endDateTime = moment(
    `${eventData?.endDate} ${defaultEndTime}`,
    'YYYY-MM-DD HH:mm:ss'
  );

  const shouldHideRegistrationButton = currentDateTime.isBefore(endDateTime);

  return (
    <Layout isMobileComingSoon={false}>
      <SEO
        title={eventData?.title}
        metaImage={eventData?.metaImage}
        description="News"
      />
      <div className="events-content-viewer-section _section-padding">
        <div className="events-content-viewer">
          <div className="content-and-side-ads-container">
            <div className="content-column">
              <div className="image-container">
                <Image
                  className="desktop-image"
                  image={
                    eventData?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData ||
                    `${process.env.GATSBY_API_URL}${eventData?.cover_image?.url}`
                  }
                />

                {eventData?.thumbnail_image ? (
                  <Image
                    className="phone-image"
                    image={
                      eventData?.thumbnail_image?.localFile?.childImageSharp
                        ?.gatsbyImageData ||
                      `${process.env.GATSBY_API_URL}${eventData?.thumbnail_image?.url}`
                    }
                  />
                ) : (
                  <div className="calendar-icon-container phone">
                    <SpriteIconViewer
                      className="calendar-icon"
                      spriteId={'calendar_icon'}
                    />
                  </div>
                )}
              </div>
              <div className="header-wrapper">
                <div className="category-tag-group">
                  <CardCategory label={eventData.datetime} />
                  {eventData?.isVCF &&
                    eventData?.progressStatus === 'ongoing' && (
                      <CardTag label={'Ongoing'} />
                    )}
                </div>
                <h1 className="events-title _section-label">
                  {eventData?.title}
                </h1>
                {eventAuthor && <div className="author">{eventAuthor}</div>}
                <CardIconInfo
                  icon={
                    <SpriteIconViewer spriteId={eventData?.location?.icon} />
                  }
                  info={eventData?.location?.name}
                />

                {shouldHideRegistrationButton && (
                  <VCFRegisterModal
                    id={eventData?.id}
                    title={eventData?.title}
                    author={eventData?.author}
                    datetime={eventData?.datetime}
                  />
                )}

                {!eventData?.isVCF &&
                  eventData?.progressStatus !== 'completed' && (
                    <InquiryModal
                      {...inquiryModalProps}
                      visible={isVisibleModal}
                      item={eventData}
                      setIsVisibleModal={setIsVisibleModal}
                    />
                  )}
              </div>

              <MarkdownViewer
                className={'article-view'}
                source={eventData?.content}
              />
            </div>

            <DesktopAds />
          </div>

          {eventData?.isVCF && !isEmpty(eventData?.employerBooths) && (
            <div className="vcf-employer-section">
              <div className="_section-label">Employers</div>
              <div className="vcf-employer-cards">
                {eventData?.employerBooths?.map?.((employerBooth) => {
                  return (
                    <VCFEmployerCard
                      avatarURL={employerBooth?.avatar_url}
                      name={employerBooth?.username}
                    />
                  );
                })}
              </div>
            </div>
          )}

          <SocialSharing path={location?.pathname} title={eventData?.title} />

          <div className="tags-container">
            {eventData?.tags?.map((tag) => (
              <Tag className="tag-label" key={tag?.name}>
                {tag?.name}
              </Tag>
            ))}
          </div>

          <AuthorPagePreview
            tagLine={previewAuthor?.details?.tagline}
            authorName={previewAuthor?.company_name || eventData?.author}
            logo={
              eventData?.authorLogoUrl ||
              previewAuthor?.avatar?.localFile?.childImageSharp?.gatsbyImageData
            }
            profileLink={selectAuthorProfileUrl([
              data?.strapiEvents,
              data?.strapiAuthorPages,
            ])}
          />

          <MobileAds />

          {!isEmpty(relatedEvents) ? (
            <div className="other-news-container">
              <CardCarousel
                title="Other events"
                items={relatedEvents}
                className={carouselStyle}
                linkRoot="/events/"
                type="eventCard"
              />
            </div>
          ) : (
            <div className="bottom-space" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EventsContentViewer;

export const query = graphql`
  query EventArticlesQuery($id: Int!, $authorId: Int) {
    strapiEvents(strapiId: { eq: $id }) {
      is_virtual_career_fair
      slug
      creator_email
      updater_email
      strapiId
      title
      content
      start_date
      end_date
      start_time
      end_time

      creator_id
      created_by {
        username
      }
      author {
        username
        avatar_url
        organization_id
      }

      tags {
        name
      }
      location {
        address
        city
        province {
          name
        }
        type
      }
      metaImage: cover {
        localFile {
          childImageSharp {
            resize(width: 1200, height: 628, quality: 100) {
              src
              width
              height
            }
          }
        }
        url
      }

      cover_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      cover_image_old: cover {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      thumbnail_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      thumbnail_image_old: thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      employer_booths {
        username
        avatar_url
      }
    }
    allRelatedEvents: allStrapiEvents(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: {
        strapiId: { ne: $id }
        published_at: { ne: null }
        is_archived: { ne: true }
      }
    ) {
      nodes {
        strapiId
        title
        slug
        created_at(formatString: "MMM DD, YYYY")
        start_date
        end_date
        start_time
        end_time
        is_virtual_career_fair

        created_by {
          username
        }
        author {
          username
        }

        location {
          province {
            name
          }
          type
        }

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }
        thumbnail_image_old: thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
    }
    strapiAuthorPages(created_by: { id: { eq: $authorId } }) {
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }
      company_name
      company_tagline
      slug
    }
  }
`;
