import React from 'react';

const CardAuthor = ({ className = '', label = '', style = {} }) => {
  const author = label == null ? 'Unauthored' : `by ${label}`;
  return (
    <div style={style} className={`card-author-container ${className}`}>
      <span className="card-author">{author}</span>
    </div>
  );
};

export default CardAuthor;
