import React from 'react';

const CardPayModel = ({ label = '' }) => {
  if (label == 'Sponsored') {
    return (
      <div className={'sponsor-cost-container'}>
        <span className={'sponsor-label'}>{label}</span>
      </div>
    );
  }
  return (
    <div className={'card-pay-model-container'}>
      <span className={'card-pay-model'}>{label}</span>
    </div>
  );
};

export default CardPayModel;
