import { isEmpty } from 'lodash';
import React from 'react';

const SponsorLogoUrl = ({ logoUrl = null, alt }) => {
  const hasNoLogoUrl = isEmpty(logoUrl);
  if (hasNoLogoUrl) return null;
  return (
    <div className={'sponsor-logo-container'}>
      <img src={logoUrl} alt={alt} />
    </div>
  );
};

export default SponsorLogoUrl;
