import React from 'react';
import { Link } from 'gatsby';
import Image from '@components/Image';

import CardCategory from '@components/Cards/CardCategory';
import CardTitle from '@components/Cards/CardTitle';
import CardIconInfo from '@components/Cards/CardIconInfo';
import CardAuthor from '@components/Cards/CardAuthor';
import CardTag from '@components/Cards/CardTag';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const Card = ({
  thumbnail_image,
  tag,
  datetime = '',
  title = '',
  author = '',
  location = { name: '', icon: '' },
  link = '',
}) => {
  const thumbnailImageUrl = thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${thumbnail_image?.url}`
    : null;

  return (
    <Link to={link}>
      <div className="event-card-section-container">
        <div className="event-card-section">
          <div className="content-image">
            {thumbnail_image ? (
              <Image
                image={
                  thumbnail_image?.localFile?.childImageSharp
                    ?.gatsbyImageData || thumbnailImageUrl
                }
              />
            ) : (
              <div className="calendar-icon-container">
                <SpriteIconViewer
                  className="calendar-icon"
                  spriteId={'calendar_icon'}
                />
              </div>
            )}
          </div>

          <div className="info-container">
            <div className="upper-info-container">
              <div className="tag-category-container">
                {datetime && <CardCategory label={datetime} />}
                {tag && <CardTag label={tag} />}
              </div>
              {title && <CardTitle label={title} />}
            </div>
            <div className="lower-info-container">
              {author && <CardAuthor label={author} />}
              {location?.name && (
                <CardIconInfo
                  icon={<SpriteIconViewer spriteId={location?.icon} />}
                  info={location?.name}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Card;
