import moment from 'moment';

const formatDateTimeString = (startDate, endDate, startTime, endTime) => {
  const isStartDateInvalid = !moment(startDate, 'YYYY-MM-DD').isValid();
  const isEndDateInvalid = !moment(endDate, 'YYYY-MM-DD').isValid();
  const isStartTimeInvalid = !moment(startTime, 'hh:mm:ss').isValid();
  const isEndTimeInvalid = !moment(endTime, 'hh:mm:ss').isValid();

  const isDateInvalid = isStartDateInvalid || isEndDateInvalid;
  const isTimeInvalid = isStartTimeInvalid || isEndTimeInvalid;

  const isInvalidDateOnly = isDateInvalid && !isTimeInvalid;
  const isInvalidTimeOnly = !isDateInvalid && isTimeInvalid;
  const isDateTimeInvalid = isDateInvalid && isTimeInvalid;
  if (isDateTimeInvalid) return '';

  const formattedStartDate = moment(startDate, 'YYYY-MM-DD').format(
    'MMM DD, YYYY'
  );
  const formattedEndDate = moment(endDate, 'YYYY-MM-DD').format('MMM DD, YYYY');
  const formattedStartTime = moment(startTime, 'hh:mm:ss').format('h:mm A');
  const formattedEndTime = moment(endTime, 'hh:mm:ss').format('h:mm A');
  const isSameStartAndEndDate = formattedStartDate === formattedEndDate;

  if (isInvalidDateOnly) return `${formattedStartTime} - ${formattedEndTime}`;

  if (isInvalidTimeOnly && isSameStartAndEndDate)
    return `${formattedStartDate}`;
  if (isInvalidTimeOnly) return `${formattedStartDate} - ${formattedEndDate}`;

  if (isSameStartAndEndDate)
    return `${formattedStartDate} | ${formattedStartTime} - ${formattedEndTime}`;
  return `${formattedStartDate} - ${formattedEndDate} | ${formattedStartTime} - ${formattedEndTime}`;
};

export default formatDateTimeString;
