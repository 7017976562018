import React from 'react';
import CardIconInfo from '@components/Cards/CardIconInfo';
import SponsorLogoUrl from '@components/SponsorLogoUrl';
import IconSignalCellular from '@components/Icons/IconSignalCellular';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import IconSchedule from '@components/Icons/IconSchedule';
import compact from 'lodash/compact';

const CourseInfo = ({
  locationIcon,
  locationName,
  duration,
  level,
  logoUrl,
  alt,
}) => {
  const cardsInfo = [locationName, duration, level];
  const compactCardsInfo = compact(cardsInfo);
  const infos = compactCardsInfo.length;

  return (
    <div>
      <section
        className={`${infos >= 3 ? 'small-group' : 'course-card-bottom-group'}`}
      >
        <div className={'second-column-group'}>
          <CardIconInfo
            icon={<SpriteIconViewer spriteId={locationIcon} />}
            info={locationName}
          />
          <CardIconInfo icon={<IconSchedule />} info={duration} />
          <CardIconInfo icon={<IconSignalCellular />} info={level} />
        </div>
        <SponsorLogoUrl logoUrl={logoUrl} alt={alt} />
      </section>
    </div>
  );
};

export default CourseInfo;
