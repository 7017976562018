import getLocationName from '@templates/CoursesContentViewer/helpers/methods/getLocationName';
import getLocationIcon from '@templates/CoursesContentViewer/helpers/methods/getLocationIcon';

const addLocationWithIcon = (
  destinationContent,
  sourceContent = destinationContent
) => {
  const eventWithLocationAndIcon = {
    ...destinationContent,

    location: {
      name: getLocationName(sourceContent?.location),
      icon: getLocationIcon(sourceContent?.location),
    },
  };

  return eventWithLocationAndIcon;
};

export default addLocationWithIcon;
