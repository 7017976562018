import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';
import modifyEventsByDateTime from '@helpers/methods/modifyContents/modifyEventsByDateTime';

import addLocationWithIcon from '../utils/addLocationWithIcon';
import selectAuthor from '../utils/selectAuthor';
import sanitizeArray from '../utils/sanitizeArray';
import selectContentImage from '../utils/selectContentImage';

const generateEventCardsProps = (events) => {
  const eventCardsProps = events.map((event) => {
    let eventCardProps = event;
    eventCardProps = addLocationWithIcon(eventCardProps, event);
    eventCardProps = selectAuthor(eventCardProps, event);
    eventCardProps = selectContentImage(eventCardProps, event);

    return eventCardProps;
  });

  return eventCardsProps;
};

const getEventCardsProps = (events) => {
  let eventCardsProps = sanitizeArray(events);

  eventCardsProps = sortByPublishedDate(eventCardsProps);
  eventCardsProps = modifyEventsByDateTime(eventCardsProps);
  eventCardsProps = generateEventCardsProps(eventCardsProps);

  return eventCardsProps;
};

export default getEventCardsProps;
