import getLocationName from '@templates/CoursesContentViewer/helpers/methods/getLocationName';
import getLocationIcon from '@templates/CoursesContentViewer/helpers/methods/getLocationIcon';
import getEventCardsProps from '@helpers/methods/modifyContents/getEventCardsProps';

const addLocation = (eventsData = []) => {
  const isDataTypeInvalid = !Array.isArray(eventsData);
  if (isDataTypeInvalid) return [];

  const isDataListEmpty = eventsData.length === 0;
  if (isDataListEmpty) return [];

  const eventsDataWithDateTimeString = eventsData.map((eventData) => {
    return {
      ...eventData,
      location: {
        name: getLocationName(eventData?.location),
        icon: getLocationIcon(eventData?.location),
      },
    };
  });

  return eventsDataWithDateTimeString;
};

const addOtherCardData = (cardsData) => {
  return cardsData.map((cardData) => {
    return {
      ...cardData,
      link: `/events/${cardData?.slug}`,
    };
  });
};

const useRelatedEventsQuery = (relatedEvents) => {
  let relatedEventsCardData = getEventCardsProps(relatedEvents);
  relatedEventsCardData = addOtherCardData(relatedEventsCardData);

  return relatedEventsCardData;
};

export default useRelatedEventsQuery;
