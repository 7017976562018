const sanitizeLocationString = (locationString) => {
  if (!locationString) return '';
  return locationString;
};

const getOnsiteLocationName = (address, city, province) => {
  const betweenAddressAndCity = !!address && !!city ? ', ' : '';
  const betweenCityAndProvince = !!city && !!province ? ', ' : '';
  const betweenAddressOrCityAndProvince =
    !!address && !!province ? ', ' : betweenCityAndProvince;

  return (
    sanitizeLocationString(address) +
    betweenAddressAndCity +
    sanitizeLocationString(city) +
    betweenAddressOrCityAndProvince +
    sanitizeLocationString(province)
  );
};

const getLocationName = (locations = []) => {
  const DEFAULT_NAME = null;
  const isLocationsEmpty = locations?.length === 0;
  if (isLocationsEmpty) return DEFAULT_NAME;

  const locationsName = locations?.map?.((location) => {
    switch (location?.type) {
      case 'online':
        return 'Online';
      case 'onsite':
        return getOnsiteLocationName(
          location?.address,
          location?.city,
          location?.province?.name
        );
      case 'distance':
        return 'Distance';
      default:
        return null;
    }
  });

  const isNameValid = !Array.isArray(locationsName);
  if (isNameValid) return DEFAULT_NAME;

  return locationsName[0];
};

export default getLocationName;
