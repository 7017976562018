import getLocationName from '@templates/CoursesContentViewer/helpers/methods/getLocationName';
import getLocationIcon from '@templates/CoursesContentViewer/helpers/methods/getLocationIcon';
import formatDateTimeString from '@helpers/methods/formatEventsDateTimeString';
import getEventProgressStatus from '@helpers/methods/getEventProgressStatus';
import selectAuthor from '@helpers/methods/modifyContents/utils/selectAuthor';
import selectAuthorLogoUrl from '@helpers/methods/modifyContents/utils/selectAuthorLogoUrl';
import selectContentImage from '@helpers/methods/modifyContents/utils/selectContentImage';

const useEventArticleQuery = (eventArticle) => {
  const metaImage = eventArticle.metaImage
    ? eventArticle.metaImage?.localFile?.childImageSharp?.resize
    : null;

  const dateTimeString = formatDateTimeString(
    eventArticle?.start_date,
    eventArticle?.end_date,
    eventArticle?.start_time,
    eventArticle?.end_time
  );

  const isVCF = !!eventArticle?.is_virtual_career_fair;

  const location = {
    name: getLocationName(eventArticle?.location),
    icon: getLocationIcon(eventArticle?.location),
  };

  let eventData;
  eventData = selectAuthor(eventData, eventArticle);
  eventData = selectAuthorLogoUrl(eventData, eventArticle);
  eventData = selectContentImage(eventData, eventArticle);

  eventData = {
    ...eventData,
    id: eventArticle?.strapiId,

    metaImage: metaImage,
    title: eventArticle?.title,
    startTime: eventArticle?.start_time,
    endTime: eventArticle?.end_time,
    startDate: eventArticle?.start_date,
    endDate: eventArticle?.end_date,
    datetime: dateTimeString,
    location: location,
    content: eventArticle?.content,
    progressStatus: getEventProgressStatus(
      eventArticle?.start_date,
      eventArticle?.end_date,
      eventArticle?.start_time,
      eventArticle?.end_time
    ),
    isVCF: isVCF,
    tags: eventArticle?.tags,

    creator_email: eventArticle?.creator_email,
    updater_email: eventArticle?.updater_email,
    slug: eventArticle?.slug,

    creator_id: eventArticle?.creator_id,
    organizationID: eventArticle?.author?.organization_id,
    employerBooths: !!eventArticle?.employer_booths
      ? eventArticle?.employer_booths
      : [],
  };

  return eventData;
};

export default useEventArticleQuery;
