import React, { useState, useRef } from 'react';
import axios from 'axios';
import Modal from 'antd/lib/modal';
import Form from 'antd/lib/form';
import Col from 'antd/lib/col';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import CardMiddot from '@components/Cards/CardMiddot';
import IconCheckedCircle from '@components/Icons/IconCheckedCircle';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorMessage from '@components/ErrorMessage';
import AgreementMessage from '@components/InquiryAgreement';
const openNotification = () => {
  notification.success({
    message: `Your inquiry has been sent. We'll be in touch shortly!`,
    placement: 'bottomLeft',
    className: 'success-notification',
    icon: <IconCheckedCircle />,
  });
};

const { TextArea } = Input;

const InquiryModal = ({
  message = 'By inquiring, you agree to be contacted by the training provider about this course.',
  item,
  setIsVisibleModal,
  visible,
  postURL = '',
  link = '',
}) => {
  const recaptchaRef = useRef();
  const [recaptchaErrorMessage, setRecaptchaErrorMessage] = useState('');
  const [form] = Form.useForm();

  const clearRecaptchaErrorMessage = () => {
    setRecaptchaErrorMessage('');
  };

  const openModal = () => {
    setIsVisibleModal(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = (e) => {
    recaptchaRef.current.reset();
    setIsVisibleModal(false);
    clearRecaptchaErrorMessage();
    form.resetFields();
    document.body.classList.remove('modal-open');
  };

  const showModalInquiry = () => {
    openModal();
  };

  const handleCancelButton = () => {
    closeModal();
  };

  const handleSendButton = () => {
    form.submit();
  };

  const handleSubmitForm = async (values) => {
    const recaptchaValue = recaptchaRef.current.getValue();
    const isRecaptchaEmpty = !recaptchaValue;
    if (isRecaptchaEmpty) {
      setRecaptchaErrorMessage('Please verify that you are a human.');
      return;
    }

    await axios
      .post(postURL, {
        title: item?.title,
        first_name: values.first_name,
        last_name: values.last_name,
        link: link,
        contact_number: values.contact_number,
        email: values.email,
        message: !values.message ? message : values.message,
        creator_email: item?.creator_email,
      })
      .then((res) => {
        closeModal();
        openNotification();
      })
      .catch((err) => console.error(err));
  };

  const onRecaptchaChange = (value) => {
    clearRecaptchaErrorMessage();
  };
  const registrationDate = item?.registrationDeadline
    ? `Register until ${item?.registrationDeadline}`
    : null;
  const courseStartDate = item?.startDate
    ? `Starts on ${item?.startDate}`
    : null;

  return (
    <>
      <Button type="primary" className="primary-btn" onClick={showModalInquiry}>
        Inquire now!
      </Button>
      <Modal
        className={'inquire-now-container'}
        title="Make an Inquiry"
        visible={visible}
        width={'100vw'}
        footer={null}
        maskClosable={false}
        onCancel={handleCancelButton}
        wrapClassName="course-inquiry-modal _livechat_overlay"
        centered={true}
      >
        <>
          <section className={'modal-body'}>
            <div className="description">
              Fill out the form and we will get back to you promptly.
            </div>
            <div className="course-info-container">
              <div className="title">{item?.title}</div>
              <div className="username">by {item?.username}</div>
              <div className="username">{item?.datetime}</div>
              <div className="sponsor">{item?.sponsorName}</div>
              <CardMiddot labels={[registrationDate, courseStartDate]} />
            </div>

            <Form form={form} layout={'vertical'} onFinish={handleSubmitForm}>
              <div className="form-input-container">
                <div className="name-container">
                  <div className="first-name-wrapper">
                    <Form.Item
                      label="First name"
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your first name.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                  <div className="last-name-wrapper">
                    <Form.Item
                      label="Last name"
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your last name.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
                <Col span={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your email.',
                      },
                      {
                        type: 'email',
                        message: 'The input is not valid E-mail.',
                      },
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Contact number"
                    name="contact_number"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your contact number.',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="Message"
                    name="message"
                    rules={[
                      {
                        max: 200,
                        message: 'Must be at most 200 characters.',
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 3, maxRows: 4 }}
                      defaultValue={''}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} className="recapatcha-container">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange}
                  />
                  <ErrorMessage message={recaptchaErrorMessage} />
                </Col>

                <AgreementMessage message={message} />
              </div>
            </Form>
          </section>

          <div className="modal-footer">
            <Button onClick={handleCancelButton} className="cancel-btn">
              Cancel
            </Button>
            <Button
              onClick={handleSendButton}
              type="primary"
              htmlType="submit"
              className="send-btn"
            >
              Send
            </Button>
          </div>
        </>
      </Modal>
    </>
  );
};

export default InquiryModal;
