import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import Notification from 'antd/lib/notification';
import isEmpty from 'lodash/isEmpty';
import IconCheckedCircle from '@components/Icons/IconCheckedCircle';
import Image from '@components/Image';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';
import { CookiesProvider, useCookies } from 'react-cookie';
import useJobseekerProfileCookie from '@helpers/hooks/useJobseekerProfileCookie';

const openNotification = () => {
  Notification.success({
    message:
      'Thank you for registering! We’ve sent a confirmation to your email',
    placement: 'bottomLeft',
    className: 'vcf-registration-success-notification',
    icon: <IconCheckedCircle />,
    closeIcon: (
      <SpriteIconViewer className="vcf-modal-close-icon" spriteId="close" />
    ),
  });
};

const VCFRegisterModal = ({ title = '', author = '', datetime = '', id }) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [registeredUser, setRegisteredUser] = useState({
    vcfRegistrationUserId: '',
    userId: '',
    isRegistered: false,
    currentlyRegisteredVCFs: [],
  });

  const jobseekerProfileCookie = useJobseekerProfileCookie();

  useEffect(() => {
    axios
      .get(
        `${process.env.GATSBY_API_URL}/vcf-registrations?user_id_eq=${jobSeekerID}`
      )
      .then((res) => {
        if (isEmpty(res?.data)) return;

        const isRegisteredToTheEvent = !isEmpty(
          res?.data?.[0]?.vcfs?.filter((event) => {
            return event?.id === id;
          })
        );

        const registeredVCFs = res?.data?.[0]?.vcfs?.map((vcf) => {
          return vcf?.id;
        });

        const registeredVCFUserID = res?.data?.[0]?.id || '';
        if (!isRegisteredToTheEvent) {
          setRegisteredUser({
            ...registeredUser,

            vcfRegistrationUserId: registeredVCFUserID,
            userId: jobSeekerID,
            isRegistered: false,
            currentlyRegisteredVCFs: registeredVCFs,
          });
          return;
        }

        setRegisteredUser({
          ...registeredUser,
          vcfRegistrationUserId: registeredVCFUserID,
          userId: jobSeekerID,
          isRegistered: true,
          currentlyRegisteredVCFs: registeredVCFs,
        });
      });
  }, []);

  const jobSeekerFirstName = jobseekerProfileCookie?.firstName;
  const jobSeekerLastName = jobseekerProfileCookie?.lastName;
  const jobSeekerID = jobseekerProfileCookie?.id;
  const jobSeekerEmail = jobseekerProfileCookie?.email;
  const jobSeekerPhotoURL = jobseekerProfileCookie?.photoUrl;
  const jobSeekerFullName = `${
    !!jobSeekerFirstName ? jobSeekerFirstName + ' ' : ''
  }${jobSeekerLastName}`;

  const openModal = () => {
    setIsModalVisible(true);
    document.body.classList.add('modal-open');
  };

  const closeModal = (e) => {
    setIsModalVisible(false);
    document.body.classList.remove('modal-open');
  };

  const handleRegisterButtonClick = () => {
    const isNotLoggedIn = !jobSeekerID;
    if (isNotLoggedIn) {
      window.location.assign(
        `${process.env.GATSBY_JOBSEEKER_URL}?externalRedirect=${window?.location?.href}`
      );

      return;
    }

    openModal();
  };

  const handleDifferentAccountLoginClick = () => {
    const isNotLoggedIn = !jobSeekerID;
    if (isNotLoggedIn) return;

    window.location.assign(
      `${process.env.GATSBY_JOBSEEKER_URL}/logout-session?externalRedirect=${window?.location?.href}`
    );
  };

  const handleCancelButtonClick = () => {
    closeModal();
  };

  const handleSendButtonClick = () => {
    const isNotLoggedIn = !jobSeekerID;
    if (isNotLoggedIn) return;
    if (registeredUser?.isRegistered) return;

    setIsRegistering(true);
    if (!!registeredUser?.vcfRegistrationUserId) {
      axios
        .put(
          `${process.env.GATSBY_API_URL}/vcf-registrations/${registeredUser?.vcfRegistrationUserId}`,
          {
            vcfs: [...registeredUser?.currentlyRegisteredVCFs, id],
          }
        )
        .then((res) => {
          closeModal();
          openNotification();
          setRegisteredUser({
            ...registeredUser,
            userId: jobSeekerID,
            isRegistered: true,
          });
        });
    } else {
      axios
        .post(`${process.env.GATSBY_API_URL}/vcf-registrations`, {
          username: jobSeekerFullName,
          email: jobSeekerEmail,
          user_id: jobSeekerID,
          vcfs: id,
        })
        .then((res) => {
          closeModal();
          openNotification();
          setRegisteredUser({
            ...registeredUser,
            userId: jobSeekerID,
            isRegistered: true,
          });
        });
    }
  };

  return (
    <>
      <CookiesProvider>
        <Button
          type="primary"
          className="primary-btn"
          onClick={handleRegisterButtonClick}
        >
          Register now
        </Button>
        <Modal
          className="vcf-register-container"
          wrapClassName="vcf-register-modal"
          visible={isModalVisible}
          footer={null}
          centered={true}
          closeIcon={
            <SpriteIconViewer
              className="vcf-modal-close-icon"
              spriteId="close"
            />
          }
          maskClosable={false}
          onCancel={handleCancelButtonClick}
        >
          <div className="vcf-event-title">Register now</div>
          <div className="vcf-event-info">
            <div className="event-title">{title}</div>
            <div className="event-author">{!!author ? `by ${author}` : ''}</div>
            <div className="event-schedule">{datetime}</div>
          </div>

          <div className="register-as-text">
            {registeredUser?.isRegistered
              ? 'You have already registered as:'
              : 'Register as:'}
          </div>

          <div className="vcf-registrant-info">
            <div className="registrant-avatar">
              {jobSeekerPhotoURL ? (
                <Image className="avatar" image={jobSeekerPhotoURL} />
              ) : (
                <SpriteIconViewer
                  className="default-avatar"
                  spriteId="user_image"
                />
              )}
            </div>

            <div className="registrant-text-info">
              <div className="registrant-name">{jobSeekerFullName}</div>
              <div className="registrant-email">{`${jobSeekerEmail}`}</div>
              <div className="registrant-other-account">
                Not you?{' '}
                <b onClick={handleDifferentAccountLoginClick}>
                  Log in with a different account
                </b>
              </div>
            </div>
          </div>
          <div className="vcf-controls">
            {registeredUser?.isRegistered ? (
              <>
                <Button
                  onClick={handleCancelButtonClick}
                  className="cancel-btn registered-cancel-btn"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleCancelButtonClick}
                  className="cancel-btn"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSendButtonClick}
                  type="primary"
                  htmlType="submit"
                  className="send-btn"
                  loading={isRegistering}
                >
                  Register
                </Button>
              </>
            )}
          </div>
        </Modal>
      </CookiesProvider>
    </>
  );
};

export default VCFRegisterModal;
